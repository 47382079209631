
export default function Header() {
    return(
        <div className="navbar bg-base-200/50 p-0 drop-shadow-lg fixed -top-0 z-40">
            <div className="navbar-start h-16">
                <div className="dropdown">
                <label tabIndex={0} className="btn btn-ghost lg:hidden text-base-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                </label>
                <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a href="/#about">Über Mich</a></li>
                    <li><a href="/#fachgebiete">Fachgebiete</a></li>
                    <li><a href="/#partner">Partner</a></li>
                    <li><a href="/#cash">Vergütung</a></li>
                    <li><a href="/#contact">Kontakt</a></li>    
                </ul>
                </div>
                <a href="/#_" className="hidden md:flex hover:bg-black/10 h-full text-base-100 cursor-pointer items-center justify-center px-8 text-xl font-semibold uppercase">Kevin Wilke</a>
            </div>
            <div className="navbar-center hidden lg:flex h-16">
                <ul className="px-1 flex flex-row h-full items-center justify-center text-base-100 font-semibold">
                {/* <ul className="menu menu-horizontal px-1 h-full tab-rounded-none"> */}
                    <li className="px-3 hover:bg-black/10 h-full cursor-pointer items-center flex justify-center"><a href="/#about">Über Mich</a></li>
                    <li className="px-3 hover:bg-black/10 h-full cursor-pointer items-center flex justify-center"><a href="/#fachgebiete">Fachgebiete</a></li>
                    <li className="px-3 hover:bg-black/10 h-full cursor-pointer items-center flex justify-center"><a href="/#partner">Partner</a></li>
                    <li className="px-3 hover:bg-black/10 h-full cursor-pointer items-center flex justify-center"><a href="/#cash">Vergütung</a></li>
                    <li className="px-3 hover:bg-black/10 h-full cursor-pointer items-center flex justify-center"><a href="/#contact"><p>Kontakt</p></a></li>
                </ul>
            </div>
            <div className="navbar-end pr-4">
                <a className="btn btn-primary btn-sm md:btn-md" href="/blog">
                    Aktuelle Rechtssprechung
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
        </svg>

                </a>
            </div>
        </div>
    )
}