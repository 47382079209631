import {useNavigate} from "react-router-dom";


export default function Impressum () {

    const navigate = useNavigate()

    return(
        <main className="w-screen h-full flex flex-col text-left justify-start items-start grow bg-white p-16">
             <button className='btn btn-primary inline-flex items-center btn-outline gap-2 self-end'
                    onClick={() => navigate("/")}>
                <svg
                    viewBox='0 0 1024 1024'
                    fill='black'
                    height='1em'
                    width='1em'
                    className='mr-1'
                >
                    <path
                        d='M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z' />
                </svg>
                Zurück zur Homepage
            </button>
            <h3 className="text-4xl font-bold">Impressum</h3>
            <h4 className="text-2xl font-semibold pl-2">Angaben gemäß § 5 TMG</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Kevin Wilke <br/>
                Rechtsanwalt <br/>
                Holbeinstraße 29 <br/>
                04229 Leipzig
            </p>
            <h4 className="text-2xl font-semibold pl-2 pt-4">Kontakt</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Telefon: +49 (0) 341 33666-85 <br/>
                Telefax: +49 (0) 341 33666-87 <br/>
                E-Mail: info@rawilke-leipzig.de
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">Berufsbezeichnung und berufsrechtliche Regelungen</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Rechtsanwalt <br/><br/>
                Zuständige Kammer: <br/>
                Rechtsanwaltskammer Sachsen <br/>
                Glacisstraße 6 <br/>
                01099 Dresden <br/><br/>
                Telefon: 0351 – 31 85 90 <br/>
                Telefax: 0351 – 33 60 899 <br/>
                E-Mail: info@rak-sachsen.de <br/>
                Internet: www.rak-sachsen.de <br/>
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">Berufsrechtliche Regelungen</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Die Berufsausübung der Rechtsanwälte unterliegt gesetzlichen Bestimmungen. Aktuelle Zusammenstellungen der geltenden Berufsregeln stellt die Rechtsanwaltskammer bereit, unter anderem unter <span className="underline "><a href="https://www.brak.de/anwaltschaft/berufsrecht/">www.brak.de</a></span> <br/>
                Es gelten folgende berufsrechtliche Regelungen:<br/>
                <a className="underline hover:text-blue-600" href="https://www.brak.de/anwaltschaft/berufsrecht/allgemeine-informationen/#c8007">Bundesrechtsanwaltsordnung (BRAO)</a><br/>
                <a className="underline hover:text-blue-600" href="https://www.brak.de/anwaltschaft/berufsrecht/allgemeine-informationen/#c8009">Berufsordnung (BORA)</a><br/>
                <a className="underline hover:text-blue-600" href="https://www.brak.de/anwaltschaft/berufsrecht/allgemeine-informationen/#c8010">Rechtsanwaltsvergütungsgesetz (RVG)</a><br/>
                <a className="underline hover:text-blue-600" href="https://www.brak.de/anwaltschaft/berufsrecht/allgemeine-informationen/#c8013">Berufsregeln der Rechtsanwälte der Europäischen Union (CCBE)</a><br/>
                Berufsrechtliche Ergänzungen zum Geldwäschegesetz (GWG)<br/>
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">Angaben zur Berufshaftpflichtversicherung</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Name und Sitz des Versicherers: <br/>
                Gothaer Allgemeine Versicherung AG <br/>
                Gothaer Allee 1 <br/>
                50969 Köln <br/>
                <br/>

                Geltungsraum der Versicherung: <br/>
                Deutschland <br/>
                Hinweis gem. § 51 BRAO: Rechtsanwälte sind durch die Bundesrechtsanwaltsverordnung verpflichtet, eine Berufshaftpflichtversicherung mit einer Mindestversicherungssumme von 250.000,- EUR zu unterhalten. Die Einzelheiten ergeben sich aus § 51 BRAO. <br/>
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">Redaktionell verantwortlich</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Rechtsanwalt Kevin Wilke
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">EU-Streitschlichtung</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                <span className="underline pl-2"><a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></span>.
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>

            <h4 className="text-2xl font-semibold pl-2 pt-4">Verbraucherstreitbeilegung/Universalschlichtungsstelle</h4>
            <p className="text-lg pl-4 pt-2 leading-6">
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
        </main>

    )
}
