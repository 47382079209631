
import { BACKEND } from '../../api/constants'

export default function Landing (data) {
    return(
      <section className='bg-white/10 w-screen h-auto sm:min-h-screen flex flex-col md:flex-row justify-end overflow-x-hidden'>
            <div className='w-full h-fit hidden md:flex'>
                <div className="avatar ml-16 mt-16 relative">
                    <div className="w-56 rounded-full shadow-2xl border-2 ">
                        <img src={BACKEND.IMG + data?.data?.Profilbild?.data?.attributes?.url} alt='' />
                    </div>
                </div>
            </div>
            <div className='flex flex-col pb-16 md:pb-0 bg-black/30 w-auto justify-center min-h-screen items-center  pt-16 sm:pt-8 md:pt-0'>
                <h1 className='md:px-10 lg:px-16 text-5xl sm:text-7xl 2xl:text-8xl font-bold text-neutral text-center w-full'>{data?.data?.Headline}</h1>
                <div className='border-b border-white/60 mx-8 w-2/3 my-3' />
                <h1 className='pb-12 md:px-10 lg:px-16 text-2xl sm:text-3xl xl:text-4xl font-medium text-neutral text-center  w-full'>{data?.data?.Subheadline}</h1>
                <div className='grid w-fit grid-cols-1 sm:grid-cols-1 gap-4 h-fit'>
                    <a className='border-2 rounded-lg text-neutral justify-center items-center flex hover:bg-primary w-72 h-20 text-2xl bg-black/20' href='#about'>Der Rechtsanwalt</a>
                    <a className='border-2 rounded-lg text-neutral justify-center items-center flex hover:bg-primary w-72 h-20 text-2xl bg-black/20' href='#fachgebiete'>Schwerpunkte</a>
                    <a className='border-2 rounded-lg text-neutral justify-center items-center flex hover:bg-primary w-72 h-20 text-2xl bg-black/20' href='#partner'>Partner</a>
                    <a className='border-2 rounded-lg text-neutral justify-center items-center flex hover:bg-primary w-72 h-20 text-2xl bg-black/20' href='#cash'>Vergütung</a>
                    <a className='border-2 rounded-lg text-neutral justify-center items-center flex hover:bg-primary w-72 h-20 text-2xl bg-black/20' href='#contact'>Kontakt</a>
                </div>
            </div>
        </section>
    )
}