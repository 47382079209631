import { BACKEND } from '../../api/constants';
import KW from '../../res/presentation.jpg';
import './stylePresentation.css'

export default function Presentation (data) {
    return(
        <section id='about' className="flex flex-col lg:flex-row w-full overflow-x-hidden">
            <img src={BACKEND.IMG + data?.data?.Image?.data?.attributes?.url} alt='Kevin Wilke' className='w-full lg:w-1/2 object-cover grow'/>
            <div className='w-full lg:w-1/2 bg-primary text-neutral py-16 px-2 sm:px-8 lg:px-16 flex flex-row'>
                <div className='flex flex-col justify-center items-center w-full'>
                        <h1 className='font-semibold text-center text-5xl 2xl:text-6xl'>{data?.data?.Headline}</h1>
                        <div className='w-32 border-b-white/60 border-b white my-3'></div>
                        <table className='table-auto lg:w-full'>
                        <tbody className='text-xl xl:text-2xl table-row-group'>
                            {data?.data?.Steckbrief?.map((item, index) => (
                                <tr className='table-row' key={index}>
                                    <td className='font-semibold pr-4 w-28 xl:w-40 text-right'>{item.Jahr}:</td>
                                    <td className='text-left '>{item.Text}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <a href='./vollmacht.pdf' download className='btn rounded-none'>download</a>                       */}
        </section>
    )
}
