import TextInputField from "./textInputField";
import { useState } from 'react'
import axios from "axios";

export default function Contact(data) {
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')


    async function sendContactMail(formData) {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_CDS_URL ?? 'https://cds.wicode.io/contact'}`,
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Auth': process.env.REACT_APP_API_KEY ,
            }
        })
    }

    const handleSubmit = async (e) => {
        console.log(e)
        e.preventDefault()
        setLoading(true)
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            message: e.target.message.value,
            subject: e.target.subject.value,
        }
        await sendContactMail(formData).then((e) => {
            console.log(e)
            if (e.data.code === 200) {
                setErrorMsg('Vielen Dank für die Nachricht! Ich melde mich in Kürze bei Ihnen.')
                setSent(true)
            } else {
                setErrorMsg(e.data?.displayMessage ?? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        }).catch((e) => {
            setErrorMsg(e?.response?.data?.DisplayMessage ?? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.')
        })
        setLoading(false)
    }
    return(
      <div className="bg-white/30 h-full w-full flex flex-row justify-center">
            <div className="flex flex-col lg:flex-row mb-24 bg-base-100 sm:rounded-lg px-8 lg:px-0 lg:py-8">
                {/* Kontaktformular */}
                <div className="py-2 px-4 md:px-8">
                    <form onSubmit={handleSubmit} autoComplete={'on'}>
                        <TextInputField id='name' width="max-w-xl" text="Vor- & Nachname" placeholder="Max Mustermann"  disabled={sent}/>
                        <div className="flex flex-col sm:flex-row justify-between max-w-xl">
                            <TextInputField id={'phone'} width="w-72 sm:w-64" text="Telefonnummer" placeholder="+01 234 567890" disabled={sent} />
                            <TextInputField id={'email'} type={'email'} width="w-72" margin="sm:ml-8" text="E-Mail" placeholder="maxmustermann@example.com" disabled={sent} />
                        </div>
                        <TextInputField id={'subject'} width="max-w-xl" text="Thema" placeholder="Der Auftrag"  disabled={sent}/>
                        <div className="form-control w-full max-w-3xl">
                            <label className="label pb-0">
                                <span className="label-text text-lg italic font-semibold text-primary">Nachricht</span>
                            </label>
                            <textarea name={'message'} className={"textarea bg-base-100 textarea-bordered max-w-xl" +
                                " h-32 text-md border-primary disabled:border-primary disabled:bg-base-100 disabled:opacity-50"} placeholder="Ich brauche einen Anwalt..." disabled={sent}></textarea>
                        </div>
                        <div className={`text-center w-full text-md`}>{errorMsg}</div>
                        <div className="flex justify-end">
                            <button
                                className={`btn btn-primary btn-outline mt-4 text-lg h-16 ${loading ? 'loading' : ''} ${sent ? 'btn-disabled opacity-50' : ''}`}
                                type={'submit'}>
                                {loading ? '' : (sent ? 'Gesendet' : 'Senden')}
                                <svg className='h-16 ml-2' fill='none' stroke='currentColor' strokeLinecap='round'
                                    strokeLinejoin='round' strokeWidth={2} viewBox='0 0 24 24' height='1.5rem'
                                    width='1.5rem'>
                                    <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z' />
                                    <path d='M22 6l-10 7L2 6' />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
                {/* Divider */}
                <div className="h-full border-b md:border-l border-slate-400"/>
                {/* Text rechts */}
                <div className="flex h-full py-4 lg:py-0 w-full lg:w-fit text-center lg:text-left px-4 lg:max-w-[19rem] flex-col justify-end">
                    <h3 className="uppercase text-4xl font-semibold pb-4">{data?.data?.Headline}</h3>
                    <p>{data?.data?.Adresszeile1}</p>
                    <p className="pb-2">{data?.data?.Adresszeile2}</p>
                    <p className="italic">Tel.: {data?.data?.Telefon}</p>
                    <p className="italic">Fax.: {data?.data?.Fax}</p>
                </div>
            </div>
      </div>
    );
}