import Presentation from '../presentation/presentation';
import CardSection from '../cards/cardSection';
import Contact from '../contact/contact';
import { PartnerSmall, PartnerBig  } from '../partner/partnerSec';
import { CashBig } from '../cash/cash';
import Landing from '../landing/landing';
import { useQuery } from '@tanstack/react-query';
import { getContact, getExpert, getPartner, getPayment, getVita, getWelcome } from '../../api/apiCalls';
import { Helmet } from 'react-helmet';

export default function HomePageRoute() {
    const { data: welcomeData } = useQuery({
        queryKey: ['welcome'],
        queryFn: getWelcome
    })  
    const { data: partnerData } = useQuery({
        queryKey: ['partner'],
        queryFn: getPartner
    })  
    const { data: vitaData } = useQuery({
        queryKey: ['vita'],
        queryFn: getVita
    })  
    const { data: contactData } = useQuery({
        queryKey: ['contact'],
        queryFn: getContact
    })  
    const { data: paymentData } = useQuery({
        queryKey: ['payment'],
        queryFn: getPayment
    })  
    const { data: expertData } = useQuery({
        queryKey: ['expert'],
        queryFn: getExpert
    })  

    return (
        <>
        <Helmet>
            <title>Rechtsanwalt Leipzig | Kevin Wilke</title>
            <meta name="og:title" content={"Rechtsanwalt Leipzig | Kevin Wilke"} />
            <meta
                name="description"
                content={"Ihr Rechtsanwalt in Leipzig für Arbeitsrecht, Verkehrsrecht, Zivilrecht und Mietrecht! Kevin Wilke | Warschat Rechtsanwälte"}
            />
            <meta
                name="og:description"
                content={"Ihr Rechtsanwalt in Leipzig für Arbeitsrecht, Verkehrsrecht, Zivilrecht und Mietrecht! Kevin Wilke | Warschat Rechtsanwälte"}
            />
            <meta name="keywords" content={"Rechtsanwalt, Anwalt, Verteidiger, Leipzig, Mietrecht, Arbeitsrecht, Verkehrsrecht, Zivilrecht"} />
            {/* <meta name="robots" content={data.metaRobots} /> */}
            <link rel="canonical" href={"https://www.rawilke-leipzig.de"} />
        </Helmet> 
        <main className="router-body flex flex-col items-center bg-black/30">
            <Landing data={welcomeData?.data?.attributes} />
            <Presentation data={vitaData?.data?.attributes}/>
            <h2 className='text-5xl text-base-100 font-bold pt-8 xl:pt-24 pb-6 xl:pb-16 w-full text-center underline bg-base-100/30' id='fachgebiete'>Fachgebiete</h2>
            <CardSection data={expertData?.data}/>
            <h2 className='text-5xl text-base-100 font-bold pt-8 xl:pt-24 pb-6 xl:pb-16 w-full text-center underline bg-base-100/30' id='partner'>Partner</h2>
            <PartnerSmall data={partnerData?.data?.attributes}/>
            <PartnerBig data={partnerData?.data?.attributes}/>
            <h2 className='text-5xl text-base-100 font-bold pt-8 xl:pt-24 pb-6 xl:pb-16 w-full text-center underline bg-base-100/30' id='cash'>Vergütung</h2>
            <CashBig data={paymentData?.data}/>
            <h2 className='text-5xl drop-shadow-2xl text-base-100 font-bold pt-8 xl:pt-24 pb-6 xl:pb-16 w-full text-center underline bg-base-100/30' id='contact'>Kontakt</h2>
            <Contact data={contactData?.data?.attributes}/>
        </main>
    </>
    )
}