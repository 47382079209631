// import {Element, ElementSmall} from "./element";
// import IMG from '../../res/finance.jpg'
// import { Accordion } from "flowbite-react";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

export function CashBig (data) {

  const [ac1, setAc1] = useState([])

  const handleAc1 = (title) => {
    if (ac1.includes(title)) {
      setAc1(ac1.filter((item) => item !== title));
    } else {
      setAc1([...ac1, title]);
    }
  };
  
  
    return(
      <div className="bg-white/30 h-full w-full flex justify-center">
        <section className="flex flex-col w-4/5 max-w-screen-2xl rounded-xl justify-center m-0">
        { data?.data?.map((item, index) => (
          <Accordion
      className="w-full rounded-lg"
      key={index}
      expanded={ac1.includes(item.attributes.Headline)} 
      onChange={() => handleAc1(item.attributes.Headline)} 
    >
      <div
        className={`w-full hover:cursor-pointer rounded-t-md ${ac1.includes(item.attributes.Headline) ? "bg-primary text-base-100" : "hover:bg-primary hover:text-base-100"}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={`panel${index}-header`} // Einzigartige ID für jeden Accordion-Header
        >
          <Typography>
            <p className="font-bold text-xl">{item.attributes.Headline}</p>
          </Typography>
        </AccordionSummary>
      </div>
      <AccordionDetails>
        <Typography className="text-justify">{item.attributes.Text}</Typography>
      </AccordionDetails>
    </Accordion>
        )) }        </section>
      </div>
    )
}

