import CostumCardHeadline from "./costumCardHeadline";
import CostumCardContent from "./costumCardContent";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

export default function CostumCard (props) {
    const [flip, setFlip] = useState(false)
    return(
        <ReactCardFlip 
                isFlipped={flip}
                flipDirection="horizontal"
            >
             <CostumCardHeadline flip={() => setFlip(!flip)} gridpos={props.gridpos} title={props.title} svg={props.svg}/>
             <CostumCardContent flip={() => setFlip(!flip)} gridpos={props.gridpos} d={props.d} text={props.text}/>
        </ReactCardFlip>
    )
}