
export default function ELEMENT (props) {
    return(
        <div className="h-full py-8 w-full bg-base-100 flex flex-col md:flex-row items-center md:px-4 lg:px-8 xl:px-48">
            <div className="avatar ">
                <div className="rounded-full bg-white w-64 overflow-hidden">
                    <img src={props.imgsrc} alt='PartnerPicture' className=" "/>
                </div>
            </div>
            <div className="flex flex-col px-8 md:pr-0 md:pl-8 w-auto xl:w-160 2xl:w-full">
                <h2 className="underline font-semibold pb-2 py-4 md:py-0 text-center text-2xl lg:text-3xl md:text-left">{props.title}</h2>
                <p className="text-lg text-justify w-auto pt-2 hyphens-auto">{props.text}</p>
            </div>
        </div>
    )
}