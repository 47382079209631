import { BACKEND } from "../../api/constants";
import CostumCard from "./costumCard";

export default function CardSection (data) {
    return(
        <div className="w-full bg-base-100/30 flex justify-center">
            <section className="inline-grid w-fit py-16 grid-rows-4 grid-cols-1 px-8 md:grid-rows-2 md:grid-cols-2 xl:grid-rows-1 xl:grid-cols-4 gap-6">
                {data?.data?.map((item, index) => (
                <CostumCard gridpos='flex ' title={item.attributes.Headline} text={item.attributes.Text} svg={BACKEND.IMG + item.attributes.Icon.data.attributes.url}/>
                ))}</section>
        </div>
    )
}