
export default function CostumCardHeadline (props) {
    return(
        <div className={`hover:cursor-pointer rounded-md bg-base-100 hover:bg-primary hover:text-base-100 ${props.gridpos}`} onClick={props.flip}>
            <div className={`flex flex-col justify-center items-center shadow-xl p-8 w-96 h-96 ${props.class}`}>
                <img src={props.svg} alt="" className="w-12 h-12" />
                <h2 className="my-2 text-2xl font-semibold">{props.title}</h2>
            </div>
        </div>
    )
}