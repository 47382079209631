import { Link } from "react-router-dom";


export default function Footer() {

    const wicodeWindow = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    return(
        <footer className="footer footer-center p-10 bg-white text-primary-content shadow-inner flex flex-col justify-center">
            <div className="flex flex-row">
                <Link className="text-md underline text-slate-400" to="/">Homepage</Link>
                <Link className="text-md underline text-slate-400" to="/impressum#_">Impressum</Link>
                <Link className="text-md underline text-slate-400" to="/datenschutz">Datenschutz</Link>
            </div>
            <div className="flex flex-row text-md text-gray-400">
                <p>Designed by </p>
                <Link onClick={() => {
                    wicodeWindow('https://www.wicode.io')
                }}><p className="underline">WICODE.</p></Link>
            </div>
        </footer>
    )
}