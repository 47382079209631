// import "./styles.css";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ELEMENT from './element.js';
// import IMG1 from './../../res/partner.jpeg'
import IMG1 from './../../res/partner.jpg'
import IMG2 from './../../res/mk.PNG'

export function PartnerBig (data) {

    const tabs = [
      { label: data?.data?.Headline },
      { label: data?.data?.Headline2 },
    ]

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
      <div className="bg-white/30 h-full w-full flex justify-center">

        <section className="hidden md:flex flex-col shadow-xl w-4/5 max-w-screen-2xl h-auto  mt-4 p-1 bg-base-100 rounded-lg">
          <div className="flex flex-row w-full relative">
               {tabs.map((item) => (
                 <div
                   key={item.label}
                  className="flex items-center justify-center w-1/2 h-16 hover:rounded-t-lg hover:bg-primary hover:text-base-100 hover:cursor-pointer font-semibold text-xl"
                  onClick={() => setSelectedTab(item)}
                 >
                      {`${item.label}`}
                   {item.label === selectedTab.label ? (
                     <motion.div className="h-16 w-1/2 border-b-2 border-violet-500 rounded-t-lg absolute flex items-center justify-center" layoutId="underline">
                     </motion.div>
                   ) : <div className="h-16 w-1/2 border-b-2 hover:bg-base-100/20 border-white/30 rounded-t-lg absolute flex items-center justify-center" layoutId="">
                     </div>}
                 </div>
               ))}
            
          </div>
          <main>
             <AnimatePresence exitBeforeEnter>
               <motion.div
                 key={selectedTab ? selectedTab.label : "empty"}
                 initial={{ y: 10, opacity: 0 }}
                 animate={{ y: 0, opacity: 1 }}
                 exit={{ y: -10, opacity: 0 }}
                 transition={{ duration: 0.2 }}
               >
                 {selectedTab.label === data?.data?.Headline2 ? <ELEMENT imgsrc={IMG1} title={data?.data?.Headline2} text={data?.data?.Text2}/> : <ELEMENT imgsrc={IMG2} title={data?.data?.Headline} text={data?.data?.Text}/>}
               </motion.div>
             </AnimatePresence>
           </main>
        </section>
      </div>

      );
    }

export function PartnerSmall (data) {
  	return(
      <section className="flex flex-col md:hidden">
        <ELEMENT imgsrc={IMG2} title={data?.data?.Headline} text={data?.data?.Text} />
        <ELEMENT imgsrc={IMG1} title={data?.data?.Headline2} text={data?.data?.Text2} />
      </section>
    )
}