
export default function TextInputField(props) {
    return(
        <div className={`form-control ${props.margin}`}>
            <label className="label pb-0">
                <span className="label-text text-lg italic font-semibold text-primary">{props.text}</span>
            </label>
            <input id={props.id ?? ""} disabled={props.disabled} name={props.id} type={props.type ?? "text"} placeholder={props.placeholder} className={`input input-bordered border-primary bg-base-100 w-full ${props.width} disabled:border-primary disabled:bg-base-100 disabled:opacity-50 ${props.disabled ? "input-disabled" : ""}`} />
        </div>
    )
}